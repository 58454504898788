.bookCover {
    height: 100px;
    margin: 0 4px;
}

#novelsContainer {
    width: 100vw;
    height: calc(100vh - min(60px, 12vw) - min(40px, 8vw) - min(1.75em, 6vw) - .67em - 100px - 60px - 80px);
    overflow: auto;
    color: white;
}

#stories {
    width: 50vw;
    min-width: 400px;
    margin: 0 auto;
}

#stories img {
    width: 100px;
    height: 100px;
}

/* .storyInfo {
    height: 100px;
    overflow: hidden;
    padding-left: .3em;
}
.storyInfo h3 {
    height: 25px;
}
.storyInfo .showNotes {
    column-width: calc(50vw - 100px - 2em);
    text-align: left;
    max-height: 75px;
}

@media screen and (max-width: 800px) {
    .storyInfo .showNotes {
        column-width: calc(400px - 100px - 2em);
    }
} */

a.story {
    display: flex;
    color: white;
    text-decoration: none;
    background-color: rgba(0, 0, 0, .25);
    box-sizing: border-box;
    border-bottom: rgba(0, 0, 0, .75) 1px solid;
}

.story h3 {
    margin:0;
    height: 25px;
    line-height: 25px;
    color: #FF0;
}

.story p {
    margin: 0;
    /* text-align: justify; */
    overflow: hidden;
    height: 75px;
}

.showNotes {
    width: 100%;
}

.showNotes > div {
    /* padding: 0 1em; */
    /* text-align: justify; */
}

@media screen and (max-width: 480px) {
    #novelsContainer {
        height: calc(100vh - min(60px, 12vw) - min(40px, 8vw) - min(1.75em, 6vw) - 6.67em - 180px);
        overflow-x: hidden;
    }
}

@media screen and (max-width: 380px) {
    #novelsContainer {
        height: calc(100vh - min(60px, 12vw) - min(40px, 8vw) - min(1.75em, 6vw) - 6.67em - 50px)
    }
    .story h3 {
        height: 20px
    }
    
    .story .showNotes {
        height: 55px;
        font-size: .9em;
    }
    .story .showNotes > div {
        height: 55px;
        overflow: hidden;
        line-height: calc(55px / 3);
    }
    #stories {
        width: 50vw;
        min-width: 380px;
        margin: 0 auto;
    }
    
    #stories img {
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 280px) {
    .story h3 {
        height: 20px
    }
    
    .story .showNotes {
        display: none
    }

    
    #stories {
        width: 50vw;
        min-width: 280px;
        margin: 0 auto;
    }
    
    #stories img {
        width: 60px;
        height: 60px;
    }
}