

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.stories {
    background-color: #FF0;
}

.novels {
    background-color: #0F0;
}

.listen {
    background-color: #0FF;
}

.buy {
    background-color: #F66;
}

.submissions {
    background-color: #444;
    color: #BBB;
}

a button, a {
    text-decoration: none;
    cursor: pointer;
}

.navContainer {
    position: fixed;
    bottom: 1em;
    width: 100%;
}
.nav {
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
}

.nav button {
    display: block;
    /* width: 80vw;
    max-width: 400px; */
    font-size: 1.5rem;
    margin: .25em auto;
    border-radius: .5em;
    height: 2em;
    width: 100%;
}

.nav a {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 100px;
}
.latest {
    position: relative;
}

.latest a {
    color: white;
}

.shareLinks {
    color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3em;
}

.shareLinks a {
    color: white;
    font-size: 2em;
    margin:0 .5em;
    padding: 0;
}

pre {
    display: none;
}

@media screen and (min-width: 481px) {
    .nav a {
        
        /* min-width: 100px; */
    }

    .nav {
        max-width: 800px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 800px) {
    .hide {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .nav button {
        border-radius: 0;
        margin: 0;
        height: 2.5em;    
        width: 100%;
        box-sizing: border-box;

    }

    .nav a {
        flex-shrink: 0;
        flex-grow: 1;
    }
    .nav {
        flex-wrap: wrap;
        
        bottom: 0;
    }

    .navContainer {
        bottom: 0;
    }

    .hide {
        display: none;
    }


}