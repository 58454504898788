form, h1 {
    color: white;
}

form > div {
    display: flex;
    justify-content: center;
    margin-bottom: .5em;
    flex-wrap: wrap;
}

label, select, input[type="text"].small {
    width: 25%;
    max-width: 100px;
    padding: .25em 0 .25em .25em;
}
input[type="text"] {
    width: 50%;
    max-width: 300px;
    padding: .25em;
}

label.forCheckbox {
    /* width: 75%; */
    width: 400px;
    max-width: 400px;
    text-align: left;
}

iframe {
    margin-right: -38px;

}
button.submit {
    font-size: 1.25em;
    padding: .5em;
    margin-top: .5em;
}
.computerOnly {
    display: flex;
}
.phoneOnly {
    display: none;
}
img.bookmark {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 1em;
    display: block;
    border: solid 2px white;
}

p.whiteText {
    width: 90%;
    max-width: 400px;
    color: white;
    margin: 0 auto 1em auto;
}

@media (max-width: 575px) {
    select, input[type="text"], input[type="text"].small {
        width: calc(75% - .5em);
        max-width: 300px;
        padding: .25em;  
    }
    label {
        width: calc(25% - .5em);
    }

    select {
        margin-bottom: .25em;
    }
    .phoneOnly {
        display: flex;
    }
    .computerOnly {
        display: none;
    }
}