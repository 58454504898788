.subscription-links {
    width: 400px;
    max-width: 95%;
    margin: 0 auto;
}
.subscription-links a {
    color: white;
    font-size: 1.25rem;
}

.emailList {
    border: 2px solid white;
    margin:-.5em auto .5em;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: .1em .1em .1em white;
    /* text-decoration: underline; */
    max-width: min(480px, 95vw);
    text-shadow: 2px 2px 2px rgba(255, 255, 255, .5);
    font-size: 1.6em;
}
/* 
.subscriptions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
} */

/* .subscriptions a {
    display: inline-block;
    background-color: #111;
   color: whitesmoke;
   font-size: 1.5rem; 
   padding: 4px;
   border: solid 1px #888;
   box-sizing: border-box;
   box-shadow: 0px 2px 3px #111 inset;
} */

.subscription-links img {
    height: 50px;
}

.link a {
    display: flex;
}

.link p {
    margin: 0;
    padding: 0;
}

.link figure {
    margin: 0;
    padding: 0;
    width: 100px;
}

.link p {
    text-align: left;
}

/* 
slider preset 
https://www.smashingmagazine.com/2021/12/create-custom-range-input-consistent-browsers/
*/

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
    -webkit-appearance: none;
     appearance: none;
     background: transparent;
     cursor: pointer;
     width: 15rem;
 }
 
 /* Removes default focus */
 input[type="range"]:focus {
   outline: none;
 }
 
 /***** Chrome, Safari, Opera and Edge Chromium styles *****/
 /* slider track */
 input[type="range"]::-webkit-slider-runnable-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;  
 }
 
 /* slider thumb */
 input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -12px; /* Centers thumb on the track */
 
    /*custom styles*/
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
 }
 
 input[type="range"]:focus::-webkit-slider-thumb {   
   border: 1px solid #053a5f;
   outline: 3px solid #053a5f;
   outline-offset: 0.125rem; 
 }
 
 /******** Firefox styles ********/
 /* slider track */
 input[type="range"]::-moz-range-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;
 }
 
 /* slider thumb */
 input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
 
    /*custom styles*/
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
 }
 
 input[type="range"]:focus::-moz-range-thumb {
   border: 1px solid #053a5f;
   outline: 3px solid #053a5f;
   outline-offset: 0.125rem; 
 }
 