#homeContainer {
    width: 100vw;
    height: calc(100vh - min(60px, 12vw) - min(40px, 8vw) - min(1.75em, 6vw) - 150px);
    overflow: auto;
}

.subscribe, .subscribe a {
    color: white;
    font-size: 1.25rem;
}

#podcastawards {
    background-color: rgba(0,0,0,.5);
    border: #0FF solid 2px;
    margin: .5em;
    padding-bottom: .5em;
    position: relative;
    max-width: min(480px, 95vw);
    margin: 0 auto;
}

#podcastawards div {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 80px);
    padding: .5em;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 1.15em;
    color: #0FF;
    text-shadow: white 0 0 15px;
}

.publication-tagline {
    display: none;
}


@media screen and (max-width: 481px) {
    #podcastawards img {
        width: 100%;
    }
}