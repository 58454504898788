form, h1 {
    color: white;
}

form > div {
    display: flex;
    justify-content: center;
    margin-bottom: .5em;
}

label {
    width: 25%;
    max-width: 100px;
    padding: .25em;
}
input[type="text"] {
    width: 50%;
    max-width: 300px;
    padding: .25em;
}

label.forCheckbox {
    /* width: 75%; */
    width: 400px;
    max-width: 400px;
    text-align: left;
}

iframe {
    margin-right: -38px;

}